(function() {
  angular.module("EntrakV5").controller("heatmapController", heatmapController);

  function heatmapController($scope, $rootScope, $state, $stateParams, Service, KEY, APIKEY, Api) {
    console.log("heatmapController");

    //$stateParams.floorId
    //$stateParams.deviceId?
    var logCaller = Api.createApiCaller();
    var caller = Api.createApiCaller();
    $rootScope.title = Service.translate("heatmap.title");
    $rootScope.setEditing(true);

    $scope.floorPlan = null;

    $scope.airconMap = {}; //init when page first load, added { ownerNodeIds[] coverage } (only include aircon type)
    $scope.nodeMap = {}; //init when page first load
    $scope.activityByDeviceMap = {}; // added { opacity requestCount max min avg records[] } refresh when user select date range or map type
    $scope.userMap = {};

    $scope.mapType = "REQUEST";
    $scope.duration = 7;
    $scope.legendDelta = null;
    $scope.loadingActivity = false;
    $scope.loadingSetPointLog = false;

    $scope.floorTemp = {};

    var wsTypeInv = APIKEY.nodeTypeInv[APIKEY.nodeType.workstation];
    var rmTypeInv = APIKEY.nodeTypeInv[APIKEY.nodeType.room];
    var coolerColor = "#319cbd";//lightOnColor
    var warmerColor = "#d0021b";
    var greyFont = "#9b9b9b";

    /* breadcrumbs */
      $scope.steps = [{
        name: "-",
        navigate: function() {
          $state.go("selectHeatmap");
        },
      }, {
        name: "-",
      }];
      $scope.stepBack = function($i, $node) {
        if ($i == 0) {
          window.history.back();
        } else {
          $node.navigate();
        }
      }
      $scope.stepNavigate = function($node) {
        $node.navigate();
      }
    /* breadcrumbs */

    /* first load */
      function processFloor(floor) {
		console.log('floor',floor)
        var hasAircon = false;
        $scope.steps[1].name = floor.name + " - " + Service.translate("heatmap.title");

        //set and center floorplan
        $scope.floorPlan = floor.floorPlan;
        var img = new Image();
        img.onload = function() {
          $scope.$apply(function() {
            var halfSize = {
              x: img.naturalWidth / 2,
              y: img.naturalHeight / 2,
            }
            $scope.mapModel.centerAt(halfSize);
          });
        }
        img.src = $scope.floorPlan;

        //fill airconMap/otherDeviceMap
        var otherDeviceMap = {}; //only for coverage calculation
		/*none gateways:*/
        // floor.gateways.forEach(function(g) {
          floor.devices.forEach(function(d) {
            if (d.location) {
              if (d.applicationType === APIKEY.applicationType.aircon) {
                hasAircon = true;
                d.ownerNodeIds = [];
                $scope.airconMap[d.id] = d;
              } else {
                otherDeviceMap[d.id] = d;
              }
            }
          });
        // });
        if (!hasAircon)
          return false;

        //fill nodeMap
        var nameSorter = Service.getSorter();
        floor.workstations.forEach(function(ws) {
          Service.updateWsName(ws);
          ws.typeInv = wsTypeInv;
          ws.deviceIds.forEach(function(id) {
            if ($scope.airconMap[id])
              $scope.airconMap[id].ownerNodeIds.push(ws.id);
          });
        });
        floor.rooms.forEach(function(rm) {
          rm.typeInv = rmTypeInv;
          rm.deviceIds.forEach(function(id) {
            if ($scope.airconMap[id])
              $scope.airconMap[id].ownerNodeIds.push(rm.id);
          });
        });
        floor.workstations.sort(nameSorter);
        floor.rooms.sort(nameSorter);
        $scope.nodeMap = Service.arrayToMap(floor.workstations);
        Service.arrayToMap(floor.rooms, "id", $scope.nodeMap);

        //find coverage
        for (var acId in $scope.airconMap) {
          var ac = $scope.airconMap[acId];
          if (ac) {
            var tmpMap = {};
            ac.ownerNodeIds.forEach(function(nId) {
              $scope.nodeMap[nId].deviceIds.forEach(function(dId) {
                tmpMap[dId] = true;
              });
            });
            ac.coverage = 0;
            for (var dId in tmpMap) {
              ac.coverage = Math.max(ac.coverage, getAirconDistanceSquare(ac, otherDeviceMap[dId]));
            }
            ac.coverage = Math.sqrt(Math.max(40 * 40, ac.coverage)) * 2 + "px"; //set min size to 80px (marker size is 40px)
          }
        }

        return true;
      }

      function processHeatmapData(mapType, res){
        if (mapType === 'REQUEST'){
          processAirconRequestActivities(res);
          $scope.loadingActivity = false;
        } else if (mapType === 'TEMPERATURE'){
          processTemperatureActivities(res);
          $scope.loadingActivity = false;
        } else {
          $scope.clearMap();
        }
      }

      function processAirconRequestActivities(res) {//TODOricky
        $scope.clearMap();
        //update legend
        if ($scope.duration == 1) {
          $scope.legendDelta = 5;
        } else if ($scope.duration == 7) {
          $scope.legendDelta = 20;
        } else {
          $scope.legendDelta = 50;
        }
        $("#heatmap .legend #legendMax").html($scope.legendDelta+"+");
        $("#heatmap .legend #legendMaxLbl").html(Service.translate("heatmap.action.warmer"));
        $("#heatmap .legend #legendMid").html("0");
        $("#heatmap .legend #legendMin").html($scope.legendDelta+"+");
        $("#heatmap .legend #legendMinLbl").html(Service.translate("heatmap.action.cooler"));
        //process data
        res.nodeActivitiesLog.forEach(function(activity) {
          if (activity.action === APIKEY.action.cooler || activity.action === APIKEY.action.warmer) {
            var node = $scope.nodeMap[activity.nodeId];
            if (node) {
              node.deviceIds.forEach(function(d) {
                if (!$scope.activityByDeviceMap[d])
                  $scope.activityByDeviceMap[d] = { records: [], requestCount: 0 };
                $scope.activityByDeviceMap[d].records.push(activity);
                $scope.activityByDeviceMap[d].requestCount += activity.action === APIKEY.action.cooler ? 1 : -1;
              });
            }
          }
        });
        //set opacity
        for (var key in $scope.activityByDeviceMap) {
          if ($scope.activityByDeviceMap.hasOwnProperty(key)) {
            var item = $scope.activityByDeviceMap[key];
            item.opacity = Math.min(Math.abs(item.requestCount), $scope.legendDelta) * 0.8 / $scope.legendDelta + 0.2; //range from 0.325 to 1 (requestCount 1 to legendDelta)
          }
        }
      }

      function processTemperatureActivities(res){
        $scope.clearMap();
        //process data
        $scope.floorTemp = {
          defaultTemperature: 0,
          max: -99999,
          min: 99999,
          avg: 0,
          avgReadings: []
        }
        var maxReadingLength = 0;//data.readings may missing some data points, maxReadingLength is to store max readings.length amount all devices
        res.forEach(function(data) {
          maxReadingLength = Math.max(maxReadingLength, data.readings.length);
        });
        var totalReadingCount = 0;
        res.forEach(function(data){
          $scope.activityByDeviceMap[data.id] = data;
          data.min = 99999;
          data.max = -99999;
          data.avg = 0;
          if (maxReadingLength != data.readings.length){
            console.error(Service.translate("error.logMissingData"), data.id, 'expected data length=' + maxReadingLength);
          }
          var nullCount = 0;
          data.readings.forEach(function(record, i){
            if (record == null){
              nullCount++;
            } else {
              data.avg += record;
              data.max = Math.max(data.max, record);
              data.min = Math.min(data.min, record);
            }
          });
          totalReadingCount += data.readings.length - nullCount;
          $scope.floorTemp.defaultTemperature += data.defaultTemperature;
          $scope.floorTemp.avg += data.avg;
          $scope.floorTemp.max = Math.max($scope.floorTemp.max, data.max);
          $scope.floorTemp.min = Math.min($scope.floorTemp.min, data.min);
          data.avg /= (data.readings.length - nullCount);
          if (isNaN(data.avg))
            data.avg = '-';
        });
        $scope.floorTemp.defaultTemperature /= res.length;
        $scope.floorTemp.avg /= totalReadingCount;
        if (isNaN($scope.floorTemp.avg))
            $scope.floorTemp.avg = '-';
        //set floorTemp.avgReadings
        for (var i=0; i<maxReadingLength; i++){
          var dataCount = 0;
          for (var j=0; j<res.length; j++) {
            var readings = res[j].readings;
            if (readings.length == maxReadingLength) {//ignore those dont have full data length
              if (readings[i] != null){
                dataCount++;
                $scope.floorTemp.avgReadings[i] = ($scope.floorTemp.avgReadings[i] || 0) + readings[i];
              }
            }
          }
          if (dataCount > 0){
            $scope.floorTemp.avgReadings[i] /= dataCount;  
          } else {
            $scope.floorTemp.avgReadings[i] = null;
          }
        }
        //update legend
        var degreeLbl = Service.translate("unit.degree");
        $scope.legendDelta = Math.max(Math.abs($scope.floorTemp.defaultTemperature-$scope.floorTemp.max), Math.abs($scope.floorTemp.defaultTemperature-$scope.floorTemp.min));
        $("#heatmap .legend #legendMax").html(Service.numFmt($scope.floorTemp.defaultTemperature+$scope.legendDelta, 1));
        $("#heatmap .legend #legendMaxLbl").html('(' + degreeLbl + ')');
        $("#heatmap .legend #legendMid").html(Service.numFmt($scope.floorTemp.defaultTemperature, 1));
        $("#heatmap .legend #legendMin").html(Service.numFmt($scope.floorTemp.defaultTemperature-$scope.legendDelta, 1));
        $("#heatmap .legend #legendMinLbl").html('');
        //set opacity
        res.forEach(function(item){
            item.opacity = Math.abs(item.avg - $scope.floorTemp.defaultTemperature) * 0.9 / $scope.legendDelta + 0.1;
        });
      }

      function getAirconDistanceSquare(aircon, otherDevice) {
        if (otherDevice) {
          var x = aircon.location.x - otherDevice.location.x;
          var y = aircon.location.y - otherDevice.location.y;
          return x * x + y * y;
        }
        return 0;
      }

      $scope.getHeatmapDataApi = function() {
        var end = new Date();
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        end = Service.getUnixTimestamp(end);
        var start = Service.addMinute(end, -$scope.duration * 24 * 60);
        end--;
        if ($scope.mapType === 'REQUEST'){
          return Api.getHeatmapPageData(start, end, $stateParams.floorId);
        } else if ($scope.mapType === 'TEMPERATURE'){
          return Api.getTemperatureLog($stateParams.floorId, start, end, $scope.duration > 7);
        }
      }
      $scope.getSetPointLogApi = function(id){
        var end = new Date();
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        end = Service.getUnixTimestamp(end);
        var start = Service.addMinute(end, -$scope.duration * 24 * 60);
        end--;
        return Api.getSetPointLog(id, start, end);
      }

      $rootScope.loadingPage = 1;
      $scope.loadingActivity = true;
      $rootScope.getTenantId().then(function() {
        $scope.steps[0].name = $rootScope.currentUser.tenant.name;
      });
      var firstMapType = $scope.mapType;
      caller.call([$scope.getHeatmapDataApi(), Api.getLocationHeatMapPage($stateParams.floorId)]).then(function(res) {
        if (processFloor(res[1])){
          processHeatmapData(firstMapType, res[0]);
          if ($stateParams.deviceId)
            $scope.selectDevice($stateParams.deviceId, true);
        } else {
          setTimeout(function() {
            $scope.noAirconWin.center().open();
          });
        }
        $rootScope.loadingPage--;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $scope.clearMap();
        $rootScope.generalErrorHandler();
      });

      $scope.noAirconWinOpt = {
        width: "600px",
        modal: true,
        draggable: false,
        visible: false,
        resizable: false,
        actions: ["Close"]
      }
      $scope.back = function(){
        window.history.back();
      }
    /* first load */

    /* map */
      $scope.mapModel = {}; //only to store directive function
      $scope.selectedData = {};
      // for aircon request
      // selectedData: { id coolCount warmCount requestRecords }
      // for temperature
      // selectedData: { id maxTemp minTemp avgTemp defaultTemp }

      $scope.clearMap = function(){
        $scope.selectedData = {};
        $scope.activityByDeviceMap = {};
      }

      $scope.onParamChanged = function(){
        logCaller.cancel();
        $scope.loadingActivity = true;
        $rootScope.loadingPage++;
        var mapType = $scope.mapType;
        caller.call($scope.getHeatmapDataApi()).then(function(res) {
          var id = $scope.selectedData.id;
          processHeatmapData(mapType, res);
          $scope.selectDevice(id);
          $rootScope.loadingPage--;
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.generalErrorHandler();
        });
      }

      $scope.dateDropdownOpt = {
        autoWidth: true,
        dataSource: [{
          text: Service.translate("label.yesterday"),
          value: 1,
        }, {
          text: Service.translate("label.last7Days"),
          value: 7,
        }, {
          text: Service.translate("label.last28Days"),
          value: 28,
        }],
        dataTextField: "text",
        dataValueField: "value",
        change: function(e) {
          $scope.$apply($scope.onParamChanged);
        },
      }

      $rootScope.getTenantId().then(function(){
        // if ($rootScope.currentUser.aclRole === APIKEY.role.super) {
          $scope.mapTypeDropdown.setDataSource(new kendo.data.DataSource({
            data: [{
              text: Service.translate("heatmap.requestCount"),
              value: "REQUEST",
            }, {
              text: Service.translate("label.temperature"),
              value: "TEMPERATURE",
            }]
          }));
      //   } else {
      //     $scope.mapTypeDropdown.setDataSource(new kendo.data.DataSource({
      //       data: [{
      //         text: Service.translate("heatmap.requestCount"),
      //         value: "REQUEST",
      //       }]
      //     }));
      //   }
      });
      $scope.mapTypeDropdownOpt = {
        autoWidth: true,
        dataSource: [{
          text: Service.translate("heatmap.requestCount"),
          value: "REQUEST",
        }],
        dataTextField: "text",
        dataValueField: "value",
        change: function(e) {
          $scope.$apply($scope.onParamChanged);
        },
      }

      $scope.legendHtml = '<div class="legend">'
                          + "<span id='legendMaxLbl'></span>"
                          + '<div class="bar">'
                            + '<div id="legendMax">-</div><div id="legendMid">-</div>'
                            + '<div id="legendMin">-</div><div class="index"></div>'
                          + "</div>"
                          + "<span id='legendMinLbl'></span>"
                        + "</div>";

      function loadChart(data, defaultTemp, max, min){
        var chart = $("#temperatureLineChart").data("kendoChart");
        var opts = {
          series: [createSeries(data)],
          valueAxis: {
            plotBands: [{
              from: defaultTemp,
              to: defaultTemp + (max-min)/50,
              color: warmerColor
            }],
          }
        }
        opts.valueAxis.max = (defaultTemp > max) ? Math.ceil(defaultTemp + 0.1) : null;
        opts.valueAxis.min = (defaultTemp < min) ? Math.floor(defaultTemp - 0.1) : null;
        chart.setOptions(opts);
        setTimeout(function(){
          chart.refresh();
        }, 100);
      }

      $scope.selectDevice = function(id, isFirstLoad) {
        logCaller.cancel();
        if (isFirstLoad && !$scope.airconMap[id]){
          $scope.selectedData = {};
          console.error('aircon not found', id);
          // $rootScope.showError(Service.translate("error.airconNotFound") + "\n" + id);
          return;
        }
        if (!id && $scope.mapType === 'TEMPERATURE'){
          $scope.selectedData = {
            maxTemp: $scope.floorTemp.max,
            minTemp: $scope.floorTemp.min,
            avgTemp: $scope.floorTemp.avg,
            defaultTemp: $scope.floorTemp.defaultTemperature
          }
          loadChart($scope.floorTemp.avgReadings, $scope.floorTemp.defaultTemperature, $scope.floorTemp.max, $scope.floorTemp.min);
        } else if ($scope.selectedData.id === id) {
          $(".etPanZoom .legend .index").css({ top: "-500px" });
          if ($scope.mapType === 'TEMPERATURE'){
            $scope.selectedData = {
              maxTemp: $scope.floorTemp.max,
              minTemp: $scope.floorTemp.min,
              avgTemp: $scope.floorTemp.avg,
              defaultTemp: $scope.floorTemp.defaultTemperature
            }
            loadChart($scope.floorTemp.avgReadings, $scope.floorTemp.defaultTemperature, $scope.floorTemp.max, $scope.floorTemp.min);
          } else {
            $scope.selectedData = {};
          }
        } else {
          $scope.selectedData = {
            id: id,
            coolCount: 0,
            warmCount: 0,
            maxTemp: 0,
            minTemp: 0,
            avgTemp: 0,
            defaultTemp: 0,
            requestRecords: null
          }
          var deviceData = $scope.activityByDeviceMap[id];
          if (deviceData) {
            var legendHeightHalf = $(".etPanZoom .legend .bar").height() / 2;
            var top = legendHeightHalf;
            if ($scope.mapType === 'REQUEST'){
              deviceData.records.forEach(function(r) {
                //should be sorted already
                if (r.action === APIKEY.action.cooler) {
                  $scope.selectedData.coolCount++;
                } else if (r.action === APIKEY.action.warmer) {
                  $scope.selectedData.warmCount++;
                }
              });
              $scope.selectedData.requestRecords = deviceData.records;
              //update index
              top += (legendHeightHalf * ($scope.selectedData.coolCount - $scope.selectedData.warmCount)) / $scope.legendDelta;
              $(".etPanZoom .legend .index").css({ top: top + "px" });
            } else if ($scope.mapType === 'TEMPERATURE'){
              $scope.selectedData.maxTemp = deviceData.max;
              $scope.selectedData.minTemp = deviceData.min;
              $scope.selectedData.avgTemp = deviceData.avg;
              $scope.selectedData.defaultTemp = deviceData.defaultTemperature;
              loadChart(deviceData.readings, $scope.selectedData.defaultTemp, deviceData.max, deviceData.min);
              //update index
              top -= (legendHeightHalf * ($scope.selectedData.avgTemp - $scope.floorTemp.defaultTemperature)) / $scope.legendDelta;
              $(".etPanZoom .legend .index").css({ top: top + "px" });
            }
          }
          if ($scope.mapType === 'REQUEST'){
            $scope.loadingSetPointLog = true;
            logCaller = Api.createApiCaller();
            logCaller.call($scope.getSetPointLogApi(id)).then(function(res){
              res.forEach(function(r){
                r.triggerBy = "SYSTEM";
                r.action = APIKEY.action.setPoint;
              });
              if ($scope.selectedData.requestRecords){
                $scope.selectedData.requestRecords = $scope.selectedData.requestRecords.concat(res);
                $scope.selectedData.requestRecords.sort(Service.getSorter('timestamp'));
              } else {
                $scope.selectedData.requestRecords = res;
              }
              $scope.loadingSetPointLog = false;
            }, function(err){
              $scope.loadingSetPointLog = false;
              if (err === KEY.ignore)
                return;
              $rootScope.generalErrorHandler(true);
            });
          }
        }
      }

      $scope.loadUser = function(id) {
        if (!$scope.userMap[id]) {
          $scope.userMap[id] = { firstName: '-' };
          caller.call(Api.getProfile(id)).then(function(res) {
            $scope.userMap[id] = res;
          }, function(err){
            if (err === KEY.ignore)
              return;
            $rootScope.generalErrorHandler();
          });
        }
        return $scope.userMap[id];
      }

      $scope.actionByLbl = function(record) {
        var triggerType = Service.translate("log.triggerType." + record.triggerBy) || Service.translate("log.triggerType.INVALID");
        if (record.triggerId){
          var triggerProfile = $scope.loadUser(record.triggerId);
          return Service.getDisplayName(triggerProfile) + " (" + triggerType + ")";
        } else {
          return triggerType;
        }
      }
    /* map */

    /* line chart */
      $scope.lineChartOpt = {
          seriesDefaults: {
              type: "line",
              stack: false,
              style: "smooth",
              opacity: 1,
              line: {
                  width: 2
              },
              markers: {
                  size: 4,
                  visible: false,
              },
              overlay: {
                  gradient: "none"
              }
          },
          series: [],
          legend: {
              visible: false
          },
          plotArea: {
              margin: 0,
              padding: 0
          },
          chartArea: {
              margin: 0,
              opacity: 0 
          },
          categoryAxis: {
              visible: false,
              majorGridLines: {
                  visible: false
              }
          },
          valueAxis: {
              visible: true,
              line: {
                visible: false,
              },
              labels: {
                color: greyFont,
                font: "10px sans-serif",
                margin: {
                  top: 5,
                  left: 5,
                  bottom: 5
                },
                template: kendo.template("#=kendo.toString(value, 'n1')#"),
              },
              majorGridLines: {
                  visible: false
              }
          },
          tooltip: {
              visible: true,
              template: "<div class='temperatureHeatmapTooltip'>#=kendo.toString(value, 'n1')#" + Service.translate("unit.degree") + "</div>",
          }
      }
      function createSeries(data){
          return {
              color: coolerColor,
              highlight: {
                  visual: function(e) {
                      var circleGeometry = new kendo.geometry.Circle(e.rect.center(), 3);
                      var circle = new kendo.drawing.Circle(circleGeometry, {
                          stroke: {
                              color: coolerColor,
                              width: 2,
                          },
                          fill: {
                              color: "white",
                          }
                      });
                      return circle;
                  },
              },
              data: data
          }
      }
    /* line chart */

    $scope.$on("$destroy", function() {
      console.log("heatmapController destroy");
      caller.cancel();
    });
  }
})();
